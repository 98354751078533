import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { ActionDialog } from '@dx-ui/osc-dialog';
import SearchButton from './shop-form.button';
import { SpecialRates } from '@dx-ui/osc-special-rates';
import { useShopFormContext } from './use-shop-form-context';
import cloneDeep from 'lodash/cloneDeep';
import { useWatch } from 'react-hook-form';

/**
 * Use the `<ShopFormSpecialRates/>` component inside of the ShopForm in order to provide the OSC experience
 * for selecting special rates to enhance the search.
 */
export type ShopFormSpecialRatesProps = {
  /** Option to enable when used outside of the shop form */
  isIndependent?: boolean;
  /** Option to control the modal open state */
  open?: boolean;
  /** Callback invoked on confirm/done */
  onConfirm?: () => void;
  /** Callback invoked on modal open */
  onOpen?: () => void;
  /** Callback invoked on cancel/dismiss */
  onDismiss?: () => void;
  /** Make smbRates SMB Context Aware */
  enableSMBContextAwareRates?: boolean;
};

export const ShopFormSpecialRates: React.FC<React.PropsWithChildren<ShopFormSpecialRatesProps>> = ({
  enableSMBContextAwareRates,
  isIndependent,
  open: openProp,
  onConfirm,
  onOpen,
  onDismiss,
}) => {
  const [t] = useTranslation(['osc-shop-form', 'osc-special-rates']);
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const {
    formState: { errors },
    reset,
    getValues,
  } = useShopFormContext();
  const hasError = !!errors.specialRates;
  const ratesValue = useWatch({ name: 'specialRates' });
  const isOfferIdSelected = useWatch({ name: 'specialRates.useOfferId' });
  const isPndSelected = useWatch({ name: 'specialRates.usePnd' });
  const isSMBSelected = useWatch({ name: 'specialRates.smbRate' });
  const isSMBMember = useWatch({ name: 'hhonors.isSMBMember' });
  const offerId = useWatch({ name: 'specialRates.offerId' });
  const pnd = useWatch({ name: 'specialRates.pnd' });
  const corporateCode = useWatch({ name: 'specialRates.corporateCode' });
  const [initialValues, setInitialValues] = React.useState<SpecialRates>();
  const selectedRates = Object.keys(ratesValue || {}).reduce((count, key) => {
    const excludedKeys = [
      'pnd',
      'usePnd',
      'offerId',
      'useOfferId',
      'requestedRatesOnly',
      'searchedRatePlanDescription',
      'travelAgentId',
    ];
    if (
      (!excludedKeys.includes(key) && Boolean(ratesValue[key as keyof typeof ratesValue])) ||
      (key === 'usePnd' && Boolean(ratesValue[key]) && ratesValue?.pnd?.name) ||
      (key === 'useOfferId' && Boolean(ratesValue[key]) && ratesValue?.offerId?.name)
    ) {
      return count + 1;
    }
    return count;
  }, 0);

  const openModal = () => {
    setInitialValues(cloneDeep(ratesValue));
    setOpen(true);
    onOpen?.();
  };

  const confirmAndCloseModal = () => {
    setOpen(false);
    onConfirm?.();
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const resetRatesToOriginalFormRates = () => {
    if (initialValues) {
      reset({ ...getValues(), specialRates: { ...initialValues } }, { keepDefaultValues: true });
    }
    onDismiss?.();
    setOpen(false);
  };

  React.useEffect(() => {
    if (isPndSelected && !pnd?.name) {
      reset({ ...getValues(), specialRates: { ...getValues().specialRates, usePnd: false } });
    }
    if (isOfferIdSelected && !offerId?.name) {
      reset({ ...getValues(), specialRates: { ...getValues().specialRates, useOfferId: false } });
    }
    if (isSMBSelected && !isSMBMember) {
      reset({ ...getValues(), specialRates: { ...getValues().specialRates, smbRate: false } });
    }
    if (isSMBSelected && corporateCode) {
      reset({ ...getValues(), specialRates: { ...getValues().specialRates, corporateCode: '' } });
    }
  }, [
    corporateCode,
    getValues,
    isOfferIdSelected,
    isPndSelected,
    isSMBMember,
    isSMBSelected,
    offerId?.name,
    pnd?.name,
    reset,
  ]);

  return (
    <>
      <SearchButton
        product="search-rates-button"
        ref={buttonRef}
        onClick={openModal}
        hasError={hasError}
        info={selectedRates === 0 ? null : selectedRates + ''}
        label={t('osc-special-rates:header')}
        aria-label={`${t('osc-special-rates:buttonLabel')} ${
          errors.specialRates
            ? t('osc-shop-form:errorHeader', { count: 1 })
            : selectedRates > 0
            ? t('osc-special-rates:buttonLabelCount', { count: selectedRates })
            : ''
        }`}
        data-testid="search-rates-button"
      />
      <ActionDialog
        className="flex flex-col overflow-auto"
        contentClassName="h-auto"
        ariaLabel={t('osc-special-rates:header')}
        title={t('osc-special-rates:header')}
        isOpen={openProp || open}
        onDismiss={resetRatesToOriginalFormRates}
        onConfirm={confirmAndCloseModal}
        size="xl"
        buttonOptions={{
          confirm: {
            disabled: !!isIndependent && hasError,
          },
        }}
      >
        <SpecialRates shouldEnableSMBContextAwareRates={enableSMBContextAwareRates} />
      </ActionDialog>
    </>
  );
};
