import * as React from 'react';
import {
  useMetrics,
  useWrappedRouter,
  useGetDreamPageData,
  useInLanguageAutocomplete,
} from '../../hooks';
import { useLocation } from '@dx-ui/framework-location-provider';
import {
  ShopFormDates,
  ShopFormLocation,
  ShopFormRooms,
  ShopFormSpecialRates,
  wrapperClassNameDefaultWithLocation,
} from '../osc-search-form/search-form-inputs';
import { useIsClient, useMediaQuery } from 'usehooks-ts';
import { useTranslation } from 'next-i18next';
import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import { sendReward } from '@dx-ui/framework-conductrics';
import { serverSideGeocodeQuery } from '../../gql/queries';
import { GOALS } from '../../constants';
import type { SortByValues } from '@dx-ui/framework-uri-builder';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { getPageType } from '../../utils';
import { getTARatingValue, getValidFilters, isPriceRangeValid } from '../filters/filter-utils';
import { ShopForm } from '../dynamic-components';
import { useRouter } from 'next/router';
import { useCoordsFromParams } from '../../hooks/use-coords-from-params';
import { useMobileShopFormFilterMvt } from '../../hooks/use-mobile-shop-form-filter-mvt';
import { useFilterState } from '../../providers/filter-provider';
import { useGetCompareHotelMVTVariants } from '../compare-hotels/hooks/use-get-compare-hotels-mvt';
import { useGetPricePlannerMVTVariants } from '../default-locations-page/price-planner/hooks/use-price-planner-mvt';
import { PlanToBookWithinBudgetButton } from '../default-locations-page/price-planner/book-within-budget-cta';
import { PlanToBookWithinBudgetBanner } from '../default-locations-page/price-planner/book-within-budget-banner';
import { logError } from '@dx-ui/framework-logger';

type PlacesSearchProps = {
  brandCode?: string;
  ctaText: string;
  hasShallowRoutingEnabled?: boolean;
  initialLocation?: string;
  matchId?: string | null;
};

export const PlacesSearch = ({
  brandCode,
  ctaText,
  hasShallowRoutingEnabled,
  initialLocation,
  matchId,
}: PlacesSearchProps) => {
  const client = useQueryClient();
  const metrics = useMetrics();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['osc-shop-form-wrapper', 'places-search']);
  const language = useRouter().locale || 'en';
  const isClient = useIsClient();
  const { shouldUseParamCoords } = useCoordsFromParams();

  const { router, safeQueryParams: searchQueryParameters } = useWrappedRouter();

  const {
    hasConnectingRooms,
    selectedCurrency = '',
    sortType,
    pageType,
    shouldUsePoints,
  } = useAppState();
  const activeFiltersState = useFilterState();

  const {
    isDefaultLocations: defaultLocationsPage,
    isHotelsNearMe: hotelsNearMePage,
    isDreams,
  } = getPageType(router.asPath);
  const showDefaultPlaceholder =
    pageType.isDefaultLocations ||
    hotelsNearMePage ||
    defaultLocationsPage ||
    pageType.isGlobal ||
    pageType.isResorts ||
    pageType.isCategoryAmenity;

  const { locationMatchName, isFetching } = useGetDreamPageData(isDreams);

  const {
    content,
    datesFlex,
    fromId,
    maxPoints,
    placeId,
    specPlan,
    redeemPts,
    requestedRatesOnly,
    sessionToken,
    specialRateTokens,
    token,
    query,
    cid,
  } = searchQueryParameters || {};

  const { showShopFormSummary, isOver321UnderTablet } = useMobileShopFormFilterMvt();
  const { isInLanguageAutocomplete, language: locale } = useInLanguageAutocomplete();
  //NHCSEARCH-5249
  const { isCompareHotelsCheckboxVariant, isCompareHotelsToggleVariant } =
    useGetCompareHotelMVTVariants();

  //NHCSEARCH-5338 - MVT - Price Planner
  const { isPricePlannerCTAVariant, isPricePlannerBannerVariant } = useGetPricePlannerMVTVariants();
  const isLargeView = useMediaQuery('(min-width: 1024px)');

  const handleFormSubmit: React.ComponentProps<typeof ShopForm>['onSubmit'] = async ({
    formData,
    url,
    locale: shopFormLocale,
  }) => {
    sendReward('g-9cHROfHNt4');
    sendReward(GOALS.DLFindAHotel);
    if (isDreams && isOver321UnderTablet) {
      sendReward(GOALS.updatedLocationsSearch);
    }
    if (url) {
      //NHCSEARCH-5249 reset compare hotels on shop form update
      if (isCompareHotelsCheckboxVariant || isCompareHotelsToggleVariant) {
        dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: [] });
      }
      // Date Search
      if (formData?.dates?.arrivalDate || formData?.dates?.departureDate) {
        if (pageType.isPointsExplorer) {
          const searchUIPages = { ...pageType, isPointsExplorer: false };
          dispatch({ type: 'SET_PAGE_TYPES', payload: searchUIPages });
        }
        if (shopFormLocale !== router.locale) {
          return window.location.assign(url);
        }
        return await router.push(url);
      }
      const formDataPlaceId = formData?.placeId !== 'null' ? formData?.placeId : undefined;
      try {
        const geocodeData = await serverSideGeocodeQuery(client, {
          address: formData?.query,
          placeId: formDataPlaceId,
          sessionToken: formData?.sessionToken,
          language: isInLanguageAutocomplete && locale ? locale : 'en',
        });
        if (!defaultLocationsPage)
          metrics.setLocationSearchName({
            locationSearchName: geocodeData?.geocode?.match?.name || '',
          });
      } catch (error) {
        if (error instanceof Error || typeof error === 'string')
          logError('ShopForm_Submit', error, 'failed to fetch geocode data');
      }
      await router.push(
        url,
        undefined,
        //shallow routing only enabled on dream page
        { shallow: hasShallowRoutingEnabled }
      );
    }
  };

  const defaultQueryValue =
    shouldUseParamCoords && query ? query : locationMatchName || initialLocation;

  const defaultValues = React.useMemo(
    () => ({
      brandCode: pageType?.isPageBrandFilterEnabled ? brandCode : 'WW',
      dates: {
        datesFlex,
      },
      placeId: placeId || matchId || '',
      query: isFetching ? '' : defaultQueryValue,
      specialRates: {
        redeemPts,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brandCode, datesFlex, placeId, matchId, isFetching, defaultQueryValue, redeemPts]
  );

  const shopFormKey = encodeURI(JSON.stringify(defaultValues));

  return isClient && router.isReady ? (
    <>
      <div
        className={cx('bg-bg', {
          'rounded-lg border border-transparent': !defaultLocationsPage,
          'p-6': hotelsNearMePage,
          'border-border-alt border-b p-4 lg:pl-10 mr-10': defaultLocationsPage,
          'border-border-alt border-b-0': isPricePlannerBannerVariant && !isLargeView,
          'gap-3': isPricePlannerCTAVariant && !isLargeView, //NHCSEARCH-5338
          'flex gap-3': isPricePlannerCTAVariant && isLargeView, //NHCSEARCH-5338
        })}
      >
        <div className={cx({ 'w-full justify-evenly': isPricePlannerCTAVariant && isLargeView })}>
          <ShopForm
            additionalQSParameters={{
              adjoiningRoomStay: hasConnectingRooms,
              brandCode: pageType?.isPageBrandFilterEnabled ? brandCode : 'WW',
              content,
              displayCurrency: selectedCurrency as string,
              fromId,
              sortBy: sortType as SortByValues,
              f_amenityIds: getValidFilters(activeFiltersState?.amenityFilters),
              f_brandCodes: getValidFilters(activeFiltersState?.brandFilters),
              f_price: isPriceRangeValid(activeFiltersState?.priceFilter)
                ? activeFiltersState?.priceFilter
                : undefined,
              f_tripAdvisorRatings: getTARatingValue(activeFiltersState?.ratingsFilter),
              maxPoints,
              requestedRatesOnly,
              specPlan,
              specialRatesTokens: specialRateTokens?.join(','),
              token,
              redeemPts: shouldUsePoints,
              cid,
            }}
            canAlwaysSubmit={defaultLocationsPage}
            cta={ctaText}
            defaultValues={defaultValues}
            key={shopFormKey}
            language={language}
            enableDatesFlexDreamRouting={true}
            onSubmit={handleFormSubmit}
            targetOHWPage="search"
            wrapperClassName={cx(wrapperClassNameDefaultWithLocation.slice(1), 'sm:justify-start')}
            shouldDisplayGeocodeErrorModal={true}
            hasErrorBanner={false}
            {...(!defaultLocationsPage &&
              showShopFormSummary && {
                summaryOptions: {
                  initiallyExpanded: false,
                  type: 'search',
                  hideRoomSummary: true,
                  summaryClassName: 'no-container',
                },
              })}
          >
            <ShopFormLocation
              coordinate={location.searchBiasCoordinate}
              key={`searchLoc-${locationMatchName}`}
              language={language}
              placeholder={showDefaultPlaceholder ? undefined : t('places-search:loadingHotels')}
              required
              sessionId={sessionToken}
            />

            <ShopFormDates hasTodayAsDefault={false} language={language} />
            {defaultLocationsPage ? (
              <>
                <ShopFormRooms
                  ageRange={{ min: 0, max: 18 }}
                  occupancyLimitMessage={t('osc-shop-form-wrapper:maxGuestsSingleRoom')}
                />
                <ShopFormSpecialRates />
              </>
            ) : null}
          </ShopForm>
        </div>
        {isPricePlannerBannerVariant && isLargeView ? <PlanToBookWithinBudgetBanner /> : null}
        <PlanToBookWithinBudgetButton />
      </div>
      {isPricePlannerBannerVariant && !isLargeView ? (
        <div className="border-border-alt  border-b">
          <PlanToBookWithinBudgetBanner />
        </div>
      ) : null}
    </>
  ) : null;
};
